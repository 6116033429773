import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { IntlService } from '@progress/kendo-angular-intl';
import {
  CreateManikinInventoryItemCommand,
  CreateManikinInventoryItemCommandResult,
  EnumListResult,
  ItemLocationViewModel,
  ListVendorsCommandResult,
  ManikinInventoryItemService,
  ManikinMakeAndModelService,
  OrganizationService,
  ReadManikinInventoryItemCommandResult,
  ListManikinMakeAndModelsCommandViewModel,
  UpdateManikinInventoryItemCommand,
  UpdateManikinInventoryItemCommandResult,
  ManikinOrTrainerBaseType,
  VendorService,
  VendorViewModel
} from '@wo-api/index';
import { EntityGlobals, ValidationMaxStringLength } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-manikin-inventory-item-detail',
  templateUrl: './manikin-inventory-item-detail.component.html',
  styleUrls: ['./manikin-inventory-item-detail.component.scss']
})
export class ManikinInventoryItemDetailComponent extends EntityBaseComponent implements OnInit {
  public dataModel: ReadManikinInventoryItemCommandResult;
  public modelInfo: ListManikinMakeAndModelsCommandViewModel;
  public readManikinInventoryItemCommandResult: ReadManikinInventoryItemCommandResult = {};
  public updateManikinInventoryItemCommand: UpdateManikinInventoryItemCommand = {};
  public createManikinInventoryItemCommand: CreateManikinInventoryItemCommand = {};

  public form: FormGroup;
  public originalFormState: FormGroup;
  public fromKey: string;

  public manikinMakeAndModelId: number = 0;
  public selectedOrganizationId: string;
  public organizations: any[];
  public manikinMakeAndModels: any[] = [];
  public defaultMakeAndModelNotSelected: boolean;
  public defaultMakeAndModel: any = {
    id: -1,
    name: 'Select an item....'
  };
  public vendorDefaultItem: VendorViewModel = {
    id: -1,
    vendorName: 'Select an item...'
  };

  public primaryLocationSubject: BehaviorSubject<AbstractControl> = new BehaviorSubject<AbstractControl>(null);
  public currentLocationSubject: BehaviorSubject<AbstractControl> = new BehaviorSubject<AbstractControl>(null);

  public skinTones: Observable<EnumListResult>;
  public statuses: Observable<EnumListResult>;
  public genders: Observable<EnumListResult>;
  public $vendors: Observable<ListVendorsCommandResult>;

  constructor(
    private logger: NGXLogger,
    private manikinMakeAndModelService: ManikinMakeAndModelService,
    private intl: IntlService,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    private manikinInventoryItemService: ManikinInventoryItemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private vendorService: VendorService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title
  ) {
    super(EntityGlobals.MANIKIN_MAKES_AND_MODELS, router, route, impersonationService, logger, breadcrumbService, titleService);

    this.skinTones = this.manikinInventoryItemService.skinTones();
    this.statuses = this.manikinInventoryItemService.statuses();
    this.genders = this.manikinInventoryItemService.genders();
    this.$vendors = this.vendorService.list();
  }

  public ngOnInit() {
    this.manikinMakeAndModelId = this.route.snapshot.params['manikinMakeAndModelId'];
    this._createForm();

    if (this.editMode) {
      this._getData();
    } else {
      this._refreshLocations();
      this.showForm = true;
    }
  }

  private _parseObjectDates(target: any): any {
    const result = Object.assign({}, target);
    var dateFields = ['dateAcquired', 'warrantyExpirationDate', 'preventativeMaintenanceExpirationDate'];
    dateFields.forEach(key => {
      const date = new Date(result[key]);
      if (!isNaN(date.getTime())) {
        result[key] = date;
      } else {
        result[key] = null;
      }
    });
    return result;
  }

  private _convertServerDataToFormModel(result: ReadManikinInventoryItemCommandResult) {
    this.dataModel = result;
    this.dataModel = this._parseObjectDates(this.dataModel);

    this.modelInfo = this.dataModel.modelInfo;
        
    this.form.patchValue(this.dataModel);

    if (this.originalFormState.pristine) {
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  private _getData(): void {
    this.manikinInventoryItemService.read(this.id).subscribe((result: ReadManikinInventoryItemCommandResult) => {
      this.UpdateBreadcrumbPageTitle(result.id, null, result.wingmanId);
      this._convertServerDataToFormModel(result);
      this._refreshLocations();
      this.showForm = true;
    });
  }

  private _createForm() {
    this.form = this.fb.group({
      id: [''],
      wingmanId: new FormControl({ value: '', disabled: true }),
      nickname: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      serialNumber: ['', [Validators.required, Validators.maxLength(ValidationMaxStringLength.Default)]],
      created: [''],
      createByUsername: [''],
      createdByUserId: [''],
      lastUpdated: [''],
      lastUpdatedByUsername: [''],
      lastUpdatedByUserId: [''],

      status: [null],
      linkToManual: [''],
      linkToTraining: [''],
      modelType: [null],
      gender: [null],
      notes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      skinTone: [null],

      purchasingVendorId: [null],
      costPerItem: [null],
      costPerUnit: [null],
      accountName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      accountNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Short)]],
      productSku: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      purchaseOrderNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      invoiceNumber: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      purchasingNotes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      fundedBy: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      dateAcquired: [null],

      warrantyVendorId: [null],
      warrantyName: ['', [Validators.maxLength(ValidationMaxStringLength.Default)]],
      preventativeMaintenanceExpirationDate: [null],
      warrantyCost: [null],
      warrantyCostPerYear: [null],
      warrantyExpirationDate: [null],
      warrantyNotes: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      warrantyType: [null],
      warrantyExpiration: [null],

      laptopTabletSerialNumber: [''],
      vitalSignsMonitorSerialNumber: [''],
      vendorOrderNumber: [''],
      invoiceTotal: [''],
      description: ['', [Validators.maxLength(ValidationMaxStringLength.Long)]],
      simulatorIssues: [''],
      tabletIssues: [''],
      vitalSignsMonitorIssues: [''],
      manikinFeaturesAndCapabilities: [''],
      organizationInventoryTagNumber: [''],

      currentLocation: this.fb.group({
        locationId: [null],
        buildingId: [null],
        roomId: [null],
        bedId: [null]
      }),
      primaryLocation: this.fb.group({
        locationId: [null],
        buildingId: [null],
        roomId: [null],
        bedId: [null]
      })
    });
    this.originalFormState = this.form;
  }

  public resetForm() {
    this.form.reset(this.originalFormState.value);
    this.routeToEntityList();
  }

  override routeToEntityList() {
    const route = `/manikin-make-and-model/${this.manikinMakeAndModelId}/line-items`;
    this.router.navigate([route]);
  }

  public onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateManikinInventoryItemCommand = this.form.value;
      this.updateManikinInventoryItemCommand.id = this.id;
      this.updateManikinInventoryItemCommand.currentLocation = this._getLocationData(this.form.value.currentLocation);
      this.updateManikinInventoryItemCommand.primaryLocation = this._getLocationData(this.form.value.primaryLocation);

      this.manikinInventoryItemService
        .update(this.id, this.updateManikinInventoryItemCommand)
        .subscribe((result: UpdateManikinInventoryItemCommandResult) => {
          if (closeOnSuccess) {
            this.routeToEntityList();
          } else {
            this._convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createManikinInventoryItemCommand = this.form.value;
      this.createManikinInventoryItemCommand.manikinMakeAndModelId = this.manikinMakeAndModelId;
      this.logger.debug('createManikinInventoryItemCommand', this.createManikinInventoryItemCommand);
      this.manikinInventoryItemService
        .create(this.createManikinInventoryItemCommand)
        .subscribe((result: CreateManikinInventoryItemCommandResult) => {
          this._convertServerDataToFormModel(result);
          this.routeToEntityList();
          this.editMode = true;
          this.toastService.success('Success!', 'This item has been saved.');
        });
    }
  }

  public submitAndClose() {
    this.onSubmit(true);
  }

  private _getLocationData(location: any): ItemLocationViewModel {
    let itemLocationViewModel: ItemLocationViewModel = {
      locationId: location.locationId ? location.locationId : 0,
      buildingId: location.buildingId ? location.buildingId : 0,
      roomId: location.roomId ? location.roomId : 0,
      bedId: location.bedId ? location.bedId : 0
    };

    if (
      itemLocationViewModel.locationId != 0 ||
      itemLocationViewModel.buildingId != 0 ||
      itemLocationViewModel.roomId != 0 ||
      itemLocationViewModel.bedId != 0
    ) {
      return itemLocationViewModel;
    } else {
      return null;
    }
  }

  public delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this manikinInventoryItem?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }

  public currentLocationSelectorChangeEvent(location: FormGroup) {
    this.form.get('currentLocation').setValue(location.value);
  }

  public primaryLocationSelectorChangeEvent(location: FormGroup) {
    this.form.get('primaryLocation').setValue(location.value);
  }

  private _refreshLocations() {
    const primaryLocation = this.form.get('primaryLocation');
    const currentLocation = this.form.get('currentLocation');

    this.primaryLocationSubject.next(primaryLocation);
    this.currentLocationSubject.next(currentLocation);
  }

  getTypeLabel(modelInfo: ListManikinMakeAndModelsCommandViewModel): string {
    if (modelInfo.manikinOrTrainerBaseType == ManikinOrTrainerBaseType.Manikin) {
      return `${modelInfo.manikinOrTrainerBaseType} - ${modelInfo.manikinType}`;
    }
    if (modelInfo.manikinOrTrainerBaseType == ManikinOrTrainerBaseType.Trainer) {
      return `${modelInfo.manikinOrTrainerBaseType} - ${modelInfo.trainerType}`;
    }
    throw new Error('This type of manikin/trainer is not allowed.');
  }
}
