<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Create {{this.GetSingleEntityName()}}</button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <!--<button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>-->
                <button kendoButton type="button" (click)="submitAndClose()" [primary]="true" [disabled]="!form.valid" [icon]="'save'">Save and Close</button>
                <button kendoButton type="button" (click)="resetForm()" [primary]="false" [icon]="'cancel-circle'">Cancel</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <!--<button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
                    <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>-->
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12" [hidden]="true">
            <kendo-buttongroup>
                <button kendoButton type="button" [primary]="true" [icon]="'user'">View Users</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class=" col-sm-12">
                        <label for="name" class="col-form-label col-form-label-sm">Template Name</label>
                        <input kendoTextBox formControlName="templateName" id="name" class="form-control" placeholder="Template Name" />
                    </div>
                    <div class=" col-md-12">
                        <label for="description" class="col-form-label col-form-label-sm">Subject</label>
                        <kendo-editor type="text" formControlName="subjectPart" id="subjectPart" class="form-control" placeholder="Subject"/>
                    </div>
                    <div class=" col-md-12">
                        <label for="htmlPart" class="col-form-label col-form-label-sm">HTML</label>
                        <kendo-editor type="text" formControlName="htmlPart" id="htmlPart" class="form-control" placeholder="HTML"/>
                    </div>
                    <div class=" col-md-12">
                        <label for="textPart" class="col-form-label col-form-label-sm">Text</label>
                        <kendo-editor type="text" formControlName="textPart" id="textPart" class="form-control" placeholder="Text"/>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Send Test Email'">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class=" col-sm-12">
                        <input kendoTextBox id="organizationId" formControlName="organizationId" class="form-control" placeholder="Organization Id" />
                    </div>
                    <div class=" col-sm-12">
                        <input kendoTextBox formControlName="username" id="username" class="form-control" placeholder="Username" />
                    </div>
                    <div class=" col-sm-12">
                        <input kendoTextBox formControlName="email" id="testEmailAddress" class="form-control" placeholder="Email Address" />
                    </div>
                    <div class=" col-sm-12">
                        <button type="button" class="btn btn-primary" (click)="SendTestEmail()">Send Test Email</button>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>