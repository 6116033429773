<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton
              type="submit"
              [disabled]="!form.valid"
              [primary]="true"
              [icon]="'save'">
              Create {{this.GetSingleEntityName()}}
            </button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <!--<button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>-->
                <button kendoButton
                  type="button"
                  [primary]="true"
                  [disabled]="!form.valid"
                  [icon]="'save'"
                  (click)="submitAndClose()">
                  Save and Close
                </button>
                <button kendoButton
                  type="button"
                  [primary]="false"
                  [icon]="'cancel-circle'"
                  (click)="resetForm()">
                  Cancel
                </button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <!--<button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable {{this.GetSingleEntityName()}}</button>
                  <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete {{this.GetSingleEntityName()}}</button>-->
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12" [hidden]="true">
            <kendo-buttongroup>
              <button kendoButton
                type="button"
                [primary]="true"
                [icon]="'user'">
                View Users
              </button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>

    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />

    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="firstName" class="col-form-label col-form-label-sm">
                          First Name
                        </label>
                        <input kendoTextBox add-required-asterisk
                          formControlName="firstName"
                          id="firstName"
                          class="form-control"
                          placeholder="First Name" />
                        <validation-error-message [control]="firstName"></validation-error-message>
                    </div>
                    <div class="col-sm-6">
                        <label for="lastName" class="col-form-label col-form-label-sm">
                          Last Name
                        </label>
                        <input kendoTextBox add-required-asterisk
                          formControlName="lastName"
                          id="lastName"
                          class="form-control"
                          placeholder="Last Name" />
                        <validation-error-message [control]="lastName"></validation-error-message>
                    </div>
                    <div class="col-sm-12">
                        <label for="email" class="col-form-label col-form-label-sm">
                          Email
                        </label>
                        <input kendoTextBox add-required-asterisk
                          formControlName="email"
                          id="email"
                          class="form-control"
                          placeholder="Email" />
                        <validation-error-message [control]="email"></validation-error-message>
                    </div>
                    <div class="col-sm-12">
                        <label for="phone" class="col-form-label col-form-label-sm">
                          Phone
                        </label>
                        <input kendoTextBox add-required-asterisk
                          formControlName="phone"
                          id="phone"
                          class="form-control"
                          placeholder="Phone" />
                        <validation-error-message [control]="phone"></validation-error-message>
                    </div>
                    <div class="col-sm-12">
                        <label for="organizationName" class="col-form-label col-form-label-sm">
                          Organization Name
                        </label>
                        <input kendoTextBox add-required-asterisk
                          formControlName="organizationName"
                          id="organizationName"
                          class="form-control"
                          placeholder="Organization Name" />
                        <validation-error-message [control]="organizationName"></validation-error-message>
                    </div>
                    <div class=" col-md-12">
                        <label for="notes" class="col-form-label col-form-label-sm">
                          Notes
                        </label>
                        <textarea kendoTextBox add-required-asterisk
                          formControlName="notes"
                          id="notes"
                          class="form-control"
                          placeholder="Notes">
                        </textarea>
                        <validation-error-message [control]="notes"></validation-error-message>
                    </div>
                    <div class=" col-sm-12">
                        <label for="customId" class="col-form-label col-form-label-sm">
                          Custom Id
                        </label>
                        <input kendoTextBox add-required-asterisk
                          formControlName="customId"
                          id="customId"
                          class="form-control"
                          placeholder="Custom Id"/>
                        <validation-error-message [control]="customId"></validation-error-message>
                    </div>
                    <div class="col-md-12">
                        <label for="description" class="col-form-label col-form-label-sm">Building</label>
                        <kendo-dropdownlist 
                            class="form-control"
                            [defaultItem]="{ name: 'Select...', value: '' }"
                            [data]="($buildings | async)?.data"
                            textField="name"
                            valueField="id"
                            [valuePrimitive]="true"
                            formControlName="buildingId">
                        </kendo-dropdownlist>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>
