import { Component, TemplateRef } from '@angular/core';

import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { Toast, ToastService } from './shared/services/toast.service';

@Component({
  selector: 'app-toasts',
  standalone: true,
  imports: [NgbToastModule, NgIf, NgTemplateOutlet, NgFor, MomentModule],
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.Options.ClassNames"
      [autohide]="toast.Options.Autohide"
      [delay]="toast.Options.DelayInMilliseconds || 5000"
      (hidden)="toastService.remove(toast)">
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.TemplateRef"></ng-template>
      </ng-template>
      <ng-template #text>
        <div class="toast-header" [hidden]="!toast?.Title">
          <strong class="me-auto">{{ toast.Title }}</strong>
          <small>{{ toast.Timestamp | amTimeAgo }}</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body" [innerHTML]="toast.Content"></div>
      </ng-template>
    </ngb-toast>
  `,
  host: { class: 'toast-container position-fixed bottom-0 end-0 p-3', style: 'z-index: 1200' }
})
export class ToastsContainerComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast: Toast) {
    return toast.TemplateRef instanceof TemplateRef;
  }
}
