import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidationExceptionApiResponse } from '@wo-api/index';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, catchError, throwError } from 'rxjs';
import { ToastService } from '../common/toast-message/shared/services';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService, private logger: NGXLogger) {}

  public readonly DefaultToastTimeoutInSeconds: number = 10;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.logger.debug('Request being called', request);
    let isRequestToGetTokenBeforeTokenEndpointConfigIsUpdated = this.isRequestToGetTokenBeforeTokenEndpointConfigIsUpdated(request);

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        this.logger.debug('Error logged in ErrorCatchingInterceptor', response);

        let error = response.error;
        if (error?.errorLabel == 'ValidationException') {
          let validationError: ValidationExceptionApiResponse = error;
          this.handleValidationException(validationError, response);
          return EMPTY;
        }

        let errorMessage = '';
        if (error.messages) {
          let messagesStr = 'There was an error fulfilling your request.<br/>';
          error.messages.forEach((item: any) => {
            messagesStr += `<li>${item}</li>`;
          });
          messagesStr = `<ul>${messagesStr}</ul>`;
          errorMessage = messagesStr;
        } else {
          errorMessage = 'There was an error.';
        }
        if (!isRequestToGetTokenBeforeTokenEndpointConfigIsUpdated) {
          this.toastService.error('Application Error', errorMessage);
        }
        return throwError(() => new Error(response.message));
      })
    );
  }

  isRequestToGetTokenBeforeTokenEndpointConfigIsUpdated(request: HttpRequest<unknown>): boolean {
    if (request.url.toLowerCase() == `token`) {
      return true;
    }
    return false;
  }

  handleValidationException(validationError: ValidationExceptionApiResponse, response: HttpErrorResponse): any {
    let modelState = validationError.modelState;
    this.toastService.validationError('Validation Error', modelState);
    return throwError(() => new Error(response.message));
  }
}
