import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { MultipleSortSettings, SelectAllCheckboxState, SelectionEvent } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  BuildingService,
  CreateRoomCommand,
  GetLocationQuantityListForRoomCommandResult,
  ListBuildingsCommand,
  ListBuildingsCommandViewModel,
  ListSupplyCountsAndLocationsQueryResult,
  LocationService,
  OrganizationService,
  ReadRoomCommandResult,
  RoomService,
  SupplyService,
  UpdateRoomCommand
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { CachedDataService, ImpersonationService, LabelType, QrLabelService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

enum HeadersWithTooltips {
  ManufacturerNumber = 'Manufacturer No.',
  UnitOfMeasurement = 'U of M'
}

@Component({
  selector: 'app-supplies-inventory-location-list',
  templateUrl: './supplies-inventory-location-list.component.html',
  styleUrls: ['./supplies-inventory-location-list.component.scss']
})
export class SuppliesInventoryLocationListComponent extends EntityBaseComponent implements OnInit {
  readRoomCommandResult: ReadRoomCommandResult = {};
  dataModel: ReadRoomCommandResult;
  listBuildingsCommand: ListBuildingsCommand = {};
  updateRoomCommand: UpdateRoomCommand = {};
  createRoomCommand: CreateRoomCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;
  buildingsForOrganization: ListBuildingsCommandViewModel[];
  buildingId: number;
  public GetLocationQuantityListForRoomCommand: Observable<GetLocationQuantityListForRoomCommandResult>;
  public selectedIds: number[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  gridData: any[];

  public sort: SortDescriptor[] = [{ dir: 'asc', field: 'supplyName' }];
  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'desc',
    allowUnsort: true,
    showIndexes: true
  };

  @ViewChild(TooltipDirective) public tooltipDirective: TooltipDirective;

  constructor(
    private sanitizer: DomSanitizer,
    private loadingBar: LoadingBarService,
    private logger: NGXLogger,
    private impersonationService: ImpersonationService,
    private organizationService: OrganizationService,
    public cachedDataService: CachedDataService,
    private roomService: RoomService,
    private buildingService: BuildingService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService,
    private locationService: LocationService,
    private supplyService: SupplyService,
    _breadcrumbService: BreadcrumbsService,
    _titleService: Title,
    private qrLabelService: QrLabelService
  ) {
    super(EntityGlobals.SUPPLIES, router, route, impersonationService, logger, _breadcrumbService, _titleService);

    this.editMode = false;
  }

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData(): void {
    this.loadingBar.useRef('router').start();
    this.supplyService.listSupplyCountsAndLocations().subscribe((value: ListSupplyCountsAndLocationsQueryResult) => {
      this.logger.debug('Got Data from GetLocationQuantityListForRoomCommandResult', value);
      this.loadingBar.useRef('router').complete();
      this.gridData = value.data;
    });
  }

  public onSelectionChange(e: SelectionEvent) {
    e.selectedRows.forEach(row => {
      if (!this.selectedIds.includes(row.dataItem.supplyLocationQuantityId)) {
        this.selectedIds.push(row.dataItem.supplyLocationQuantityId);
      }
    });
    e.deselectedRows.forEach(row => {
      const index = this.selectedIds.indexOf(row.dataItem.supplyLocationQuantityId);
      if (index > -1) {
        this.selectedIds.splice(index, 1);
      }
    });

    const selectionLength = this.selectedIds.length;
    if (selectionLength === 0) {
      this.selectAllState = 'unchecked';
    } else if (selectionLength > 0 && selectionLength < this.gridData.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public onSelectAll(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.selectedIds = this.gridData.map(item => item.supplyLocationQuantityId);
      this.selectAllState = 'checked';
    } else {
      this.selectedIds = [];
      this.selectAllState = 'unchecked';
    }
  }

  public async onPrintLabels(): Promise<void> {
    const labelType = LabelType.Supply;
    const selectedItems = this.gridData.filter(gridRow => this.selectedIds.includes(gridRow.supplyLocationQuantityId));
    await this.qrLabelService.setQrLabelsInBrowserStorage(
      (item: any) => `${location.origin}/${EntityGlobals.SUPPLIES.baseRoute}/qr/supply-location-quantity/${item.supplyLocationQuantityId}`,
      labelType,
      selectedItems
    );
    this.router.navigateByUrl(`/printer/print-labels/${labelType.toLowerCase()}`);
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (
      element.className.includes('k-column-title') &&
      Object.values(HeadersWithTooltips).includes(element.innerText as HeadersWithTooltips)
    ) {
      this.tooltipDirective.toggle(element);
    } else {
      this.tooltipDirective.hide();
    }
  }

  public getTooltipText(innerText: string): string {
    switch (innerText) {
      case HeadersWithTooltips.ManufacturerNumber:
        return 'Manufacturer Number';
      case HeadersWithTooltips.UnitOfMeasurement:
        return 'Unit of Measurement';
      default:
        return innerText;
    }
  }
  @ViewChild('selectAllCheckbox') selectAllCheckbox: ElementRef;
  clickSelectAllCheckbox() {
    this.selectAllCheckbox.nativeElement.click();
  }
}
